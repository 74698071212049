import React, { useState, useEffect, useRef } from "react";
import { useInView, InView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";

// components
import Footer from "../components/Footer";
import Swiper from "../components/Swiper";
import SolutionSection from "../components/SolutionSection";
import ClientSection from "../components/ClientSection";
import ShowcaseSection from "../components/ShowcaseSection";
import ContactUsSection from "../components/ContactUsSection";
import BannerSection from "../components/BannerSection";
import MobileNavbar from "../components/MobileNavbar";
import LoadingLogo from "../components/LoadingLogo";

// data
import { HomeSections } from "../data/appConstants";

// styles
import { useTheme, useMediaQuery } from "@material-ui/core";
import styled from "styled-components";

// images
import DesktopTestimonials from "../images/desktop-testimonials.png";
import MobileTestimonials from "../images/mobile-testimonials.png";
import AwardsLogo from "../images/awards2.png";

import { Helmet } from "react-helmet";

export interface Section {
  id: string;
}

const threshold = 0.9;

const SwiperContainer = styled.div`
  position: fixed;
  width: 10%;
  height: 100vh;
  z-index: 1000;
  display: grid;
  place-items: center end;
  right: 0;
`;

const LoadingLogoWrapper = styled.div`
  width: 50%;
  height: 50%;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translate(50%, 50%);
  overflow: hidden;
`;

function HomePage() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"));
  const { pathname, hash } = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [loadedSections, setLoadedSections] = useState(0);
  const [activeSection, setActiveSection] = useState<string>("banner-section");

  useEffect(() => {
    console.log("loaded sections", loadedSections);
    if (loadedSections === 5) {
      setIsLoading(false);
    }
  }, [loadedSections]);

  // handle hash
  useEffect(() => {
    console.log("home page pathname:", pathname, "hash:", hash);
    if (hash === "") {
      window.scrollTo(0, 0);
      return;
    }

    setTimeout(() => {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }, 100);
  }, [pathname, hash]);

  return mobileView ? (
    <>
      <Helmet>
        <title>Digital marketing agency in Hong Kong | RDS Digital</title>
        <meta name="description" content="Holistic Digital Solutions include digital marketing, social marketing, influencers marketing, 小紅書 廣告, online media planning" />
      </Helmet>

      <MobileNavbar />

      <div id={HomeSections[0].id}>
        <InView threshold={threshold}>
          {({ ref, inView }) => {
            if (inView) setActiveSection(HomeSections[0].id);
            return (
              <div ref={ref}>
                <BannerSection
                  setActiveSection={setActiveSection}
                  setLoadedSections={setLoadedSections}
                  loadedSections={loadedSections}
                />
              </div>
            );
          }}
        </InView>
      </div>

      <div id={HomeSections[1].id}>
        <InView threshold={threshold}>
          {({ ref, inView }) => {
            if (inView) setActiveSection(HomeSections[1].id);
            return (
              <div ref={ref}>
                <SolutionSection
                  setActiveSection={setActiveSection}
                  setLoadedSections={setLoadedSections}
                  loadedSections={loadedSections}
                />
              </div>
            );
          }}
        </InView>
      </div>

      {/* awards */}
      {/* <div style={{ margin: 0, padding: "2rem 0 0", backgroundColor: "black" }}>
        <img
          src={AwardsLogo}
          alt="awards"
          style={{
            margin: 0,
            padding: "1rem -2rem",
            width: "100%",
          }}
        />
      </div> */}

      {/* youtube video @ mobile view*/}
      {/*
      <div id="mobile-youtube-video">
        <InView threshold={threshold}>
          {({ ref, inView }) => {
            return (
              <div
                ref={ref}
                style={{
                  margin: "0 auto",
                  padding: "2rem 0 ",
                  backgroundColor: "black",
                }}
              >
                <img
                  src={AwardsLogo}
                  alt="awards"
                  style={{
                    margin: 0,
                    padding: "0 0 2rem",
                    width: "100%",
                  }}
                />
                <iframe
                  width="90%"
                  // height="315"
                  src="https://www.youtube.com/embed/UUimhj5VQPU"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ backgroundColor: "black" }}
                ></iframe>
              </div>
            );
          }}
        </InView>
      </div>
      */}

      <div id="mobile-testimonials">
        <InView threshold={threshold}>
          {({ ref, inView }) => (
            <div
              ref={ref}
              style={{ position: "relative", backgroundColor: "black" }}
            >
              <img
                src={MobileTestimonials}
                alt="testimonials"
                style={{ width: "90%", marginBottom: "5rem" }}
              />
            </div>
          )}
        </InView>
      </div>

      <div id={HomeSections[2].id}>
        <InView threshold={threshold}>
          {({ ref, inView }) => {
            if (inView) setActiveSection(HomeSections[2].id);
            return (
              <div ref={ref}>
                <ClientSection
                  setActiveSection={setActiveSection}
                  setLoadedSections={setLoadedSections}
                  loadedSections={loadedSections}
                />
              </div>
            );
          }}
        </InView>
      </div>

      <div id={HomeSections[3].id}>
        <InView threshold={threshold}>
          {({ ref, inView }) => {
            if (inView) setActiveSection(HomeSections[3].id);
            return (
              <div ref={ref}>
                <ShowcaseSection
                  setActiveSection={setActiveSection}
                  setLoadedSections={setLoadedSections}
                  loadedSections={loadedSections}
                />
              </div>
            );
          }}
        </InView>
      </div>

      <div id={HomeSections[4].id}>
        <InView threshold={threshold}>
          {({ ref, inView }) => {
            if (inView) setActiveSection(HomeSections[4].id);
            return (
              <div ref={ref}>
                <ContactUsSection
                  setActiveSection={setActiveSection}
                  setLoadedSections={setLoadedSections}
                  loadedSections={loadedSections}
                />
              </div>
            );
          }}
        </InView>
      </div>

      <Footer />
    </>
  ) : (
    <>
      <div
        className={isLoading ? "show" : "hidden"}
        style={{ width: "100vw", height: "100vh", backgroundColor: "black" }}
      >
        <LoadingLogoWrapper>
          <LoadingLogo />
        </LoadingLogoWrapper>
      </div>

      <div className={isLoading ? "hidden" : "show"}>
        <SwiperContainer>
          <Swiper
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          />
        </SwiperContainer>

        <div id={HomeSections[0].id}>
          <InView threshold={threshold}>
            {({ ref, inView }) => {
              if (inView) setActiveSection(HomeSections[0].id);
              return (
                <div ref={ref}>
                  <BannerSection
                    setActiveSection={setActiveSection}
                    setLoadedSections={setLoadedSections}
                    loadedSections={loadedSections}
                  />
                </div>
              );
            }}
          </InView>
        </div>

        <div id={HomeSections[1].id}>
          <InView threshold={threshold}>
            {({ ref, inView }) => {
              if (inView) setActiveSection(HomeSections[1].id);
              return (
                <div ref={ref}>
                  <SolutionSection
                    setActiveSection={setActiveSection}
                    setLoadedSections={setLoadedSections}
                    loadedSections={loadedSections}
                  />
                </div>
              );
            }}
          </InView>
        </div>

        {/* awards */}
        <div style={{ margin: 0, padding: "2rem 0", backgroundColor: "black" }}>
          <img
            src={AwardsLogo}
            alt="awards"
            style={{ margin: 0, padding: "1rem -1rem", width: "100%" }}
          />
        </div>

        {/* youtube video */}
        {/*
        <div id="desktop-youtube-video">
          <InView threshold={threshold}>
            {({ ref, inView }) => {
              return (
                <div
                  ref={ref}
                  style={{
                    position: "relative",
                    height: "100vh",
                    backgroundColor: "black",
                  }}
                >
                  <iframe
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    width="75%"
                    height="75%"
                    src="https://www.youtube.com/embed/UUimhj5VQPU"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              );
            }}
          </InView>
        </div>
        */}
        <div id="destop-testimonials">
          <InView threshold={threshold}>
            {({ ref, inView }) => (
              <div
                ref={ref}
                style={{ position: "relative", backgroundColor: "black" }}
              >
                <img
                  src={DesktopTestimonials}
                  alt="testimonials"
                  style={{ width: "90%", marginBottom: "5rem" }}
                />
              </div>
            )}
          </InView>
        </div>

        <div id={HomeSections[2].id}>
          <InView threshold={0.5}>
            {({ ref, inView }) => {
              if (inView) setActiveSection(HomeSections[2].id);
              return (
                <div id={HomeSections[2].id} ref={ref}>
                  <ClientSection
                    setActiveSection={setActiveSection}
                    setLoadedSections={setLoadedSections}
                    loadedSections={loadedSections}
                  />
                </div>
              );
            }}
          </InView>
        </div>

        <div id={HomeSections[3].id}>
          <InView threshold={threshold}>
            {({ ref, inView }) => {
              if (inView) setActiveSection(HomeSections[3].id);
              return (
                <div ref={ref}>
                  <ShowcaseSection
                    setActiveSection={setActiveSection}
                    setLoadedSections={setLoadedSections}
                    loadedSections={loadedSections}
                  />
                </div>
              );
            }}
          </InView>
        </div>

        <div id={HomeSections[4].id}>
          <InView threshold={threshold}>
            {({ ref, inView }) => {
              if (inView) setActiveSection(HomeSections[4].id);
              return (
                <div ref={ref}>
                  <ContactUsSection
                    setActiveSection={setActiveSection}
                    setLoadedSections={setLoadedSections}
                    loadedSections={loadedSections}
                  />
                </div>
              );
            }}
          </InView>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default HomePage;
